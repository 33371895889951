<template>
    <div>
        <div class="entry-short" v-bind:style="{ backgroundImage: 'url(' + cmsPageHeaderImage + ')' }">
            <div v-if="teamMember" class="context">
                <h1 class="date-title">Team</h1>
            </div>
        </div>

        <section class="component-container white" v-if="teamMember">
            <div class="team-member">
                <div v-if="teamMember" class="team-member-portrait">
                    <picture>
                        <template v-for="[format, set] of Object.entries(srcsets)">
                            <source v-if="format !== originalFormat" :srcset="set" sizes="(max-width: 1100px) 75vw, 50vw"
                                :type="'image/' + format.slice(1)">
                        </template>
                        <img loading="lazy" decoding="async" :srcset="srcsets[originalFormat]"
                            :type="'image/' + originalFormat.slice(1)" sizes="(max-width: 1100px) 75vw, 50vw"
                            :alt="teamMemberImageAltTag">
                    </picture>
                </div>

                <div class="team-member-content">
                    <div class="details">
                        <h2>{{ teamMember.attributes.name }}</h2>
                        <div>{{ localize(teamMember.attributes.hashtags) }}</div>
                    </div>

                    <div class="description" v-html="localize(teamMember.attributes.bio)"></div>
                    <div class="description">
                        <div v-if="teamMember.attributes.telephone">{{ teamMember.attributes.telephone }} </div>
                        <div v-if="teamMember.attributes.email">{{ teamMember.attributes.email }} </div>
                    </div>
                    <div class="share-links">
                        <div class="share-link-items">
                            <a class="icon" v-if="teamMember.attributes.telephone"
                                :href="'tel:' + teamMember.attributes.telephone">
                                <img src="@/assets/phone_black.svg">
                            </a>
                            <a class="icon" v-if="teamMember.attributes.email"
                                :href="'mailto:' + teamMember.attributes.email">
                                <img src="@/assets/mail.svg">
                            </a>
                            <a class="icon" v-if="teamMember.attributes.website" :href="teamMember.attributes.website"
                                target="_blank">
                                <img src="@/assets/website_icon_black.svg">
                            </a>
                            <a class="icon" v-if="teamMember.attributes.linkedIn" :href="teamMember.attributes.linkedIn"
                                target="_blank">
                                <img src="@/assets/linked-in-black.png">
                            </a>
                            <a class="icon" v-if="teamMember.attributes.instagram" :href="teamMember.attributes.instagram"
                                target="_blank">
                                <img src="@/assets/instagram_black.png">
                            </a>
                            <a class="icon" v-if="teamMember.attributes.twitter" :href="teamMember.attributes.twitter"
                                target="_blank">
                                <img src="@/assets/twitter_icon_black.svg">
                            </a>
                            <a class="icon" v-if="teamMember.attributes.facebook" :href="teamMember.attributes.facebook"
                                target="_blank">
                                <img src="@/assets/facebook_icon_black.svg">
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { teamMemberQuery, globalOptionQuery } from '@/queries'
import ImageBlock from '@/components/PageBuilder/ImageBlock'
import Separator from '@/components/PageBuilder/Separator'
import TeamMember from '@/components/PageBuilder/Elements/TeamMember'
import { config } from '@/config'
//import Cris from '@/cris' 


export default {
    name: 'Team',

    components: {
        ImageBlock,
        Separator,
        TeamMember,
    },

    data() {
        return {
            teamMember: null,
            route: this.$route
        }
    },

    apollo: {
        teamMember: {
            query: teamMemberQuery,
            variables() {
                return {
                    slug: this.$route.params.slug
                }
            },
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: true,
            update: data => data.teamMembers.data[0]
        },

        defaultCmsPageHeader: {
            query: globalOptionQuery,
            variables: {
                optionId: 'default-cms-page-header'
            },
            fetchPolicy: 'cache-first',
            update: data => data.globalOptions.data[0]
        }
    },

    metaInfo() {
        var meta = []

        if (this.metaTitle) {
            meta.push({
                vmid: 'og:title',
                name: 'og:title',
                content: this.metaTitle
            })
        }
        if (this.metaDescription) {
            meta.push({
                vmid: 'description',
                name: 'description',
                content: this.metaDescription
            })
            meta.push({
                vmid: 'og:description',
                name: 'og:description',
                content: this.metaDescription
            })
        }
        if (this.metaImage) {
            meta.push({
                vmid: 'og:image',
                name: 'og:image',
                content: this.metaImage
            })
        }

        return {
            title: this.metaTitle,
            meta: meta
        }
    },

    computed: {
        teamMemberImageAltTag() {
            if (this.teamMember && this.teamMember.attributes.image) {
                return this.teamMember.attributes.image.data.attributes.alternativeText
            } else if (this.placeholderImage) {
                return this.placeholderImage.alternativeText
            }
        },

        srcsets() {
            if (this.teamMember && this.teamMember.attributes.image.data) {
                return this.getImageSrcsets(this.teamMember.attributes.image.data.attributes);
            }
            return this.getImageSrcsets(this.placeholderImage.data.attributes);
        },

        hasWebp() {
            return this.srcsets[".webp"] ? true : false;
        },

        originalFormat() {
            for (const [format, _] of Object.entries(this.srcsets)) {
                if (format !== ".webp") {
                    return format;
                }
            }
            return null;
        },

        cmsPageHeaderImage() {
            return this.defaultCmsPageHeader ? this.getImageUrl(this.localize(this.defaultCmsPageHeader.attributes.image).data.attributes.url) : ''
        },

        metaTitle() {
            if (this.teamMember && this.teamMember.attributes.name) {
                return config.globalPageTitle + ' Team - ' + this.teamMember.attributes.name
            }

            return config.globalPageTitle + ' - ' + 'Unser Team'
        },

        metaDescription() {
            if (this.teamMember && this.teamMember.attributes.bio) {
                return this.localize(this.teamMember.attributes.bio)
            }
            return null
        },

        metaImage() {
            if (this.teamMember && this.teamMember.attributes.image) {
                return this.getImageUrl(this.teamMember.attributes.image.data.attributes.url)
            }
            return null
        },
    },
    methods: {
        clickTeam() {
            console.log("Button Clicked")
            console.log(this.$matomo.isUserOptedOut())
        }
    }
}
</script>

<style lang="scss" scoped>
.component-container {
    padding: 50px 20px;

    .team-member {
        max-width: $container-width;
        margin: 0 auto;
        display: flex;
        flex-direction: row;

        @include breakpoint('mobile') {
            flex-direction: column;
        }

        .team-member-portrait {
            width: 50%;
            height: 600px;
            min-width: 400px;

            @include breakpoint('mobile') {
                width: 100%;
                height: auto;
                min-width: unset;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .team-member-content {
            margin-left: 25px;
            width: 50%;

            @include breakpoint('mobile') {
                width: 100%;
                margin-left: 0px;
                padding-top: 25px;
            }

            .details {
                font-size: getFontSize('defaultText', 'desktop');

                @include breakpoint('tablet') {
                    font-size: getFontSize('defaultText', 'tablet');
                }

                @include breakpoint('mobile') {
                    font-size: getFontSize('defaultText', 'mobile');
                }

                h2 {
                    text-align: left;
                    margin-top: 0;
                }
            }

            .description {
                margin-top: 25px;
                font-size: getFontSize('smallText', 'desktop');
                text-align: start;

                @include breakpoint('tablet') {
                    font-size: getFontSize('smallText', 'tablet');
                }

                @include breakpoint('mobile') {
                    font-size: getFontSize('smallText', 'mobile');
                }
            }

            .share-links {
                margin-top: 25px;

                .share-link-items {

                    a {
                        margin: 10px;

                        img {
                            width: 30px;
                        }
                    }
                }
            }


        }


    }
}</style>
